<template>
  <!-- 侵权报案详情 -->
  <div class="positionT0L0">
    <p class="title">侵权报案信息</p>
    <div class="line">
      <div class="left">报案号：</div>
      <div class="right">{{ form.bizNo }}</div>
    </div>
    <div class="line">
      <div class="left">审核状态：</div>
      <div class="right">{{ form.auditStatusText }}</div>
    </div>
    <div class="line">
      <div class="left">侵权方式：</div>
      <div class="right" v-if="form.type == 1">线上</div>
      <div class="right" v-if="form.type == 2">线下</div>
    </div>
    <template v-if="form.type == 1">
      <div class="line">
        <div class="left">平台名称：</div>
        <div class="right">{{ form.platformName }}</div>
      </div>
      <div class="line">
        <div class="left">店名：</div>
        <div class="right">{{ form.onlineStoreName }}</div>
      </div>
      <div class="line">
        <div class="left">链接：</div>
        <div class="right">{{ form.link }}</div>
      </div>
    </template>
    <template v-if="form.type == 2">
      <div class="line">
        <div class="left">侵权地址：</div>
        <div class="right">{{ form.sellerName }}</div>
      </div>
      <div class="line">
        <div class="left">挡口号：</div>
        <div class="right">{{ form.pawnshop }}</div>
      </div>
      <div class="line">
        <div class="left">店名：</div>
        <div class="right">{{ form.offlineStoreName }}</div>
      </div>
    </template>
    <div class="line">
      <div class="left">侵权者信息：</div>
      <div class="right">{{ form.address }}</div>
    </div>
    <div class="line">
      <div class="left">投诉人姓名：</div>
      <div class="right">{{ form.fullName }}</div>
    </div>
    <div class="line">
      <div class="left">投诉人公司：</div>
      <div class="right">{{ form.company }}</div>
    </div>
    <div class="line">
      <div class="left">投诉人电话：</div>
      <div class="right">{{ form.phone }}</div>
    </div>

    <FooterToolBar :collapsed="sideCollapsed">
      <a-button
        class="margin_right60"
        @click="() => $router.push(`/crm/tortList`)"
      >
        返回
      </a-button>
    </FooterToolBar>
  </div>
</template>

<script>
import { TortDetailApi } from "@/request/api/crm";
import FooterToolBar from "@/components/FooterToolbar";
import { baseMixin } from "@/store/app-mixin";
export default {
  mixins: [baseMixin],
  components: { FooterToolBar },
  created() {
    let id = this.$route.params.id;
    TortDetailApi({ id: id }).then(({ code, data }) => {
      if (code == 200) {
        this.form = data;
      }
    });
  },
  data() {
    return {
      form: {},
    };
  },
};
</script>

<style lang="less" scoped>
.title {
  padding: 10px 16px;
  color: #00153c;
  background: #f4f8ff;
  font-weight: 600;
}
.line {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  color: #00153c;
  .left {
    width: 100px;
    text-align: right;
  }
}
</style>
